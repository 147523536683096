<template lang="pug">
  .log-field.d-flex.flex-column.align-end
    slot(:loading="loading" :sendLog="sendLog" :updateLog="updateLog" :log="log")
      v-textarea(
        v-model="log"
        auto-grow
        dark
        flat
        full-width
        placeholder="Type your text here…"
        rows="2"
        :loading="loading"
        @keydown="sendOnHotkey"
      ).log-field__text
      v-btn(
        color="primary"
        @click="sendLog"
        :disabled="loading || !log"
        data-test="custom-log-btn"
      ).btn-common.btn-commmon_bold.log-field__btn.px-6
        | Send
        v-icon(right) mdi-arrow-right
</template>

<script>
import Svc from "../../core/candidates-service";
import ErrMixin from "@/mixins/errors.mixin"
import { isMac } from '../../../../../../util';

export default {
  props: {
    ID: {
      type: Number,
      required: true
    }
  },

  mixins: [ErrMixin],

  data: () => ({
    log: '',
    loading: false,
    svc: null
  }),

  created() {
    this.init()
  },

  mounted() {
  },

  methods: {
    init() {
      this.svc = new Svc();
    },

    updateLog(val) {
      this.log = val
    },

    async sendLog() {
      try {
        this.loading = true;
        let res = await this.svc.addCustomLog(this.ID, this.log);
        this.$emit('send', res)
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      } finally {
        this.loading = false;
        this.log = '';
      }
    },

    sendOnHotkey(e) {
      if(!this.log || this.loading) return
      if (e.ctrlKey && e.code === "Enter") {
        this.sendLog()
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  .log-field {
    padding: 10px 14px 14px 14px;
    background-color: $log-secondary;

    &__btn {
      margin-top: 4px;
    }

    &__text {
      width: 100%;
      &::v-deep {
        .v-input__slot {
          &::after,
          &::before {
            display: none;
          }
        }
        textarea {
          color: $log-default;
          font-weight: 500;
          font-size: 14px;
          &::placeholder {
            color: $log-default;
            font-weight: 500!important;
            font-size: 14px;
          }
        }
      }
    }
  }

</style>
